import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import { AspectRatio } from "react-aspect-ratio";
import imageSize from "@coderosh/image-size";
import WIPState from "../shared/WIPState";

const TypePhoto = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [main, setMain] = useState();
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [shortcutDesc, setShortcutDesc] = useState("");
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/portfolio/description/${id}`)
      .then((res) => {
        setPortfolio(res.data);
        setShortcutDesc(
          res.data.text.split(" ").slice(0, 50).join(" ") + "..."
        );
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/image/images/portfolio/main/${id}`
      )
      .then((res) => {
        setMain(res.data);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/image/images/portfolio/link/${id}`
      )
      .then((res) => {
        setImageUrl(res.data);
        setLoading(false);
      });
  }, [id]);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`${portfolio.title} | Veronika Vavrincová`}
          description={`${shortcutDesc}`}
          name="Veronika Vavrincová"
          type="Portfolio"
        />
        <Navbar />
        <Container>
          <div className="spacer mobile portfolio"> </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={imageUrl}
            slide={lightboxController.slide}
          />
          <div className="content-page animate-in">
            <Row>
              <Col sm={12} md={12} lg={6} xl={6}>
                <div className="types-desc">
                  <h1>{portfolio.title}</h1>
                  <p>{<UnsafeComponent html={portfolio.price} />}</p>
                  <UnsafeComponent html={portfolio.text} />
                  <Link to="/kontakt">
                    <button className="send-button">KONTAKTUJTE MĚ</button>
                  </Link>
                </div>
              </Col>
              <Col className="desktop" sm={12} md={12} lg={6} xl={6}>
                <img
                  src={`/assets/images/portfolio/${id}/${main}`}
                  alt="rodinne"
                  className="type-photo type"
                />
              </Col>
            </Row>
            <div className="image-gallery-types">
              {imageUrl.map((image, index) => {
                var ratio = imageSize(image);
                ratio = ratio.height + "/" + ratio.width;
                return (
                  <div className="gallery__image" key={image.src}>
                    <AspectRatio ratio={ratio}>
                      <img
                        src={`${image}`}
                        onClick={() => openLightboxOnSlide(index + 1)}
                        alt="demo"
                      />
                    </AspectRatio>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default TypePhoto;
