import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Unauthorized from "../../../shared/Unauthorized";

const getNameOfPage = (page) => {
  if (page === "about") {
    return "O mně";
  } else if (page === "pricing") {
    return "Ceník";
  } else if (page === "portfolio") {
    return "Portfolio";
  } else if (page === "gallery") {
    return "Galerie";
  } else if (page === "contact") {
    return "Kontakt";
  } else if (page === "blog") {
    return "Blog";
  }
};

function Editor(props) {
  const params = useParams();
  const [text, setText] = useState("");
  const accessToken = useSelector((state) => state.form.accessToken);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/main/text`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setText(response.data);
      });
  }, [accessToken, params.id]);

  const saveText = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/text/main/text/save`,
        {
          text: text
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        navigate("/admin/dashboard");
      });
  };

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"></div>
          <div className="d-flex justify-content-evenly align-center">
            <h1 className="mb-3">{getNameOfPage(params.id)}</h1>
            <Link className="editor-button left" to="/admin/dashboard">
              <button className="send-button editor">ZPĚT</button>
            </Link>
          </div>
          <div className="login-form">
            <input
              name="text"
              type="text"
              placeholder="Text na hlavní stránku"
              className="control"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <button
            className="send-button save mb-3"
            type="submit"
            onClick={() => saveText()}
          >
            ULOŽIT
          </button>
        </Container>
      </>
    );
  }
}

export default Editor;
