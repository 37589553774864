import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Unauthorized from "../../../shared/Unauthorized";

function Editor(props) {
  const [theme, setTheme] = useState("snow");
  const { id, inputRef, placeholder } = props;
  const [text, setText] = useState("");
  const accessToken = useSelector((state) => state.form.accessToken);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/about/text`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setText(response.data);
      });
  }, [accessToken]);

  const saveText = () => {
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/text/about/text/save`,
      {
        text: text,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    ).then(() => {
      navigate("/admin/dashboard");
    });
  };

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
  return (
    <>
      <NavbarAdmin />
      <Container className="animate-in">
        <div className="spacer mobile main"></div>
        <div className="d-flex justify-content-evenly align-center">
          <Link className="editor-button left" to="/admin/dashboard">
            <button className="send-button editor">ZPĚT</button>
          </Link>
          <h1 className="mb-3">O mně</h1>
        </div>
        <ReactQuill
          id={id}
          ref={inputRef}
          theme={theme}
          onChange={(value) => setText(value)}
          value={text}
          modules={{
            toolbar: {
              ...Editor.modules.toolbar,
              handlers: {},
            },
            ...Editor.modules,
          }}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={placeholder ?? ""}
        />{" "}
        <button
          className="send-button save mb-3"
          type="submit"
          onClick={() => saveText()}
        >
          ULOŽIT
        </button>
      </Container>
    </>
  );}
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    [{ align: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default Editor;
