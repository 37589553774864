import React, { useEffect } from "react";
import { SET_ACCESS_TOKEN } from "../../reducer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import SEO from "../../shared/SEO";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Nesprávný email!").required("Povinné"),
  password: Yup.string().required("Povinné"),
});

const Login = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.form.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/account/gettoken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
        if (accessToken) {
          navigate("/admin/dashboard");
        }
      });
  }, [accessToken, dispatch, navigate]);

  return (
    <>
      <SEO title="Admin | Přihlášení" />
      <div className="login-card animate-in">
        <h2>Vítej zpět!</h2>
        <h3>Zadej údaje k přihlášení</h3>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/account/login`,
                {
                  email: values.email,
                  password: values.password,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  },
                }
              )
              .then((response) => {
                dispatch({
                  type: SET_ACCESS_TOKEN,
                  payload: response.data.accessToken,
                });
                if (response.data.accessToken) {
                  navigate("/admin/dashboard");
                }
              });
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <Field
                name="email"
                type="email"
                placeholder="jan.novak@email.cz"
                className="control"
              />
              {errors.email && touched.email ? (
                <div className="error-input">{errors.email}</div>
              ) : null}

              <Field
                name="password"
                type="password"
                placeholder="Heslo"
                className="control"
              />
              {errors.password && touched.password ? (
                <div className="error-input">{errors.password}</div>
              ) : null}

              <button className="control" type="submit">
                PŘIHLÁSIT SE
              </button>
            </Form>
          )}
        </Formik>
        <Link to="/password/forgot">
          <button className="send-button">ZAPOMENUTÉ HESLO</button>
        </Link>
        <Link to="/">
          <button className="send-button">ZPĚT DOMŮ</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
