import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment-with-locales-es6";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import WIPState from "../shared/WIPState";

const Blog = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/blog/preview`)
      .then((res) => {
        setPortfolio(res.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/header/blog`)
      .then((res) => {
        setFileName(res.data);
        setLoading(false);
      });
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  function renderPortfolio() {
    moment.locales("cs");

    return portfolio.map((item, index) => {
      var textItem = "";
      textItem = item.text.split(" ").slice(0, 20).join(" ") + "...";
      return (
        <Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12" key={index}>
          <div className="card-content-pricing">
            <img
              className="card-image-content blog"
              src={`/assets/images/blog/${item.id}/${item.imageToBlogs.fileName}`}
              alt="druhy foceni"
              F
            />
            <div className="text-pricing">
              <h3>{item.title}</h3>
              <p className="text-muted">
                {moment(`${item.dateWritten}`).locale("cs").format("L")} |{" "}
                {item.lengthRead} min. čtení | {item.category}
              </p>
              <p>{<UnsafeComponent html={textItem} />}</p>
              <Link to={`/blog/${item.id}`}>
                <button className="send-button">VÍCE</button>
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`Blog | Veronika Vavrincová`}
          description={`Portfolio | Veronika Vavrincová`}
          name="Veronika Vavrincová"
          type="Blog"
        />
        <Navbar />
        <div className="spacer mobile main"> </div>
        <div className="page-header-pricing animate-in">
          <img
            class="page-img-header"
            src={`/assets/images/blog/${fileName}`}
            alt="Portfolio"
          />
          <h1>BLOG</h1>
        </div>
        <Container>
          <div className="content-page-items animate-in">
            <div className="types-cards">
              <Row>{renderPortfolio()}</Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default Blog;
