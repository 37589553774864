import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <div className="login-card animate-in">
        <h2>Děkuji za zprávu!</h2>
        <h3>Pokusím se Vám, co&nbsp;nejrychleji odpovědět</h3>
        <Link to="/">
          <button className="send-button">DOMŮ</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
