import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment-with-locales-es6";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const PortfolioList = () => {
  const [portfolio, setPortfolio] = useState([]);
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/blog/preview`)
      .then((res) => {
        setPortfolio(res.data);
      });
  }, []);

  function renderPortfolioTable() {
    return portfolio.map((portfo, index) => {
      return (
        <tr className="my-list">
          <td className="id">{portfo.id}</td>
          <td className="title">{portfo.title}</td>
          <td className="title">
            {moment(`${portfo.dateWritten}`).locale("cs").format("L")}
          </td>
          <div className="icons">
            <td className="icon">
              <i
                class="bi bi-trash3"
                onClick={() => deletePortfolio(portfo.id)}
              ></i>
            </td>
            <td className="icon">
              <Link to={`/admin/add/blog/images/list/${portfo.id}`}>
                <i class="bi bi-card-image"></i>
              </Link>
            </td>
            <td className="icon">
              <Link to={`/admin/edit/blog/${portfo.id}`}>
                <i class="bi bi-pencil"></i>
              </Link>
            </td>
          </div>
        </tr>
      );
    });
  }

  function deletePortfolio(id) {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/blog/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setPortfolio(res.data);
      });
  }

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"></div>
          <div className="d-flex justify-content-end">
            <Link to="/admin/dashboard" className="editor-button left">
              <button className="send-button editor">ZPĚT</button>
            </Link>
            <Link to="/admin/add/blog" className="editor-button right">
              <button className="send-button editor">PŘIDAT</button>
            </Link>
          </div>
          <Table
            responsive
            className="bg-light text-dark mt-3 border-radius p-1 rounded"
          >
            <tr className="my-list head">
              <td className="id">Id</td>
              <td className="title">Název</td>
              <td className="title">Datum</td>
              <div className="icons">
                <td className="icon"></td>
                <td className="icon"></td>
                <td className="icon"></td>
              </div>
            </tr>
            {renderPortfolioTable()}
          </Table>
        </Container>
      </>
    );
  }
};

export default PortfolioList;
