import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import WIPState from "../shared/WIPState";

const Pricing = () => {
  const [pricing, setPricing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/portfolio/pricing/preview`)
      .then((res) => {
        setPricing(res.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/header/pricing`)
      .then((res) => {
        setFileName(res.data);
        setLoading(false);
      });
  }, []);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  function renderPricing() {
    return pricing.map((item, index) => {
      return (
        <Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12" key={index}>
          <div className="card-content-pricing">
            <img
              className="card-image-content blog"
              src={`/assets/images/portfolio/${item.id}/${item.imageToPortfolios.fileName}`}
              alt="druhy foceni"
            />
            <div className="text-pricing">
              <h3>{item.title}</h3>
              <p className="mt-5">{<UnsafeComponent html={item.price} />}</p>
              <Link to={`/portfolio/${item.id}`}>
                <button className="send-button">VÍCE</button>
              </Link>
            </div>
          </div>
        </Col>
      );
    });
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`Ceník | Veronika Vavrincová`}
          description={`Ceník mých služeb.`}
          name="Veronika Vavrincová"
          type="Ceník"
        />
        <Navbar />
        <div className="spacer mobile main"> </div>
        <div className="page-header-pricing animate-in">
          <img
            class="page-img-header"
            src={`/assets/images/pricing/${fileName}`}
            alt="Portfolio"
          />
          <h1>CENÍK</h1>
        </div>
        <Container>
          <div className="content-page-items animate-in">
            <div className="types-cards">
              <Row>{renderPricing()}</Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default Pricing;
