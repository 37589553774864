import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import axios from "axios";
import { Link } from "react-router-dom";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";
import { AspectRatio } from "react-aspect-ratio";
import imageSize from "@coderosh/image-size";

function GalleryList() {
  const [images, setImages] = useState([]);
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery/link`)
    .then((res) => {
        setImages(res.data);
      });
  }, []);

  function deleteImage(fileName) {
    console.log(fileName);
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/image/delete/image/gallery/${fileName}`
      )
      .then((res) => {
        setImages(res.data);
        window.location.reload();
      });
  }

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"> </div>
          <div className="d-flex justify-content-evenly align-center">
            <Link className="editor-button left" to="/admin/dashboard">
              <button className="send-button editor">ZPĚT</button>
            </Link>
            <Link
              className="editor-button right"
              to="/admin/image/upload/gallery"
            >
              <button className="send-button editor">PŘIDAT FOTKY</button>
            </Link>
          </div>
          <div className="content-page animate-in">
            <h1>GALERIE</h1>
          </div>
          <div className="image-gallery-types">
            {images.map((image, index) => {
              var ratio = imageSize(image);
              ratio = ratio.height + "/" + ratio.width;
              return (
                <div className="gallery__image" key={index}>
                  <AspectRatio ratio={ratio}>
                    <img src={`${image}`} />
                    <button
                      className="delete-photo"
                      onClick={() => {
                        deleteImage(image.split("gallery/")[1]);
                      }}
                    >
                      &times;
                    </button>
                  </AspectRatio>
                </div>
              );
            })}
          </div>
        </Container>
      </>
    );
  }
}

export default GalleryList;
