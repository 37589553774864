import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Povinné"),
  newpassword: Yup.string().required("Povinné"),
  renewpassword: Yup.string()
    .required("Povinné")
    .equals([Yup.ref("newpassword")], "Hesla se neshodují"),
});

const Login = (props) => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.form.accessToken);
  var url = window.location.search;
  url = url.replace("?token=", "");

  return (
    <>
      <div className="login-card animate-in">
        <h2>Změna hesla</h2>
        <h3></h3>
        <Formik
          initialValues={{
            email: "",
            newpassword: "",
            renewpassword: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/account/resetPassword`,
                {
                  email: values.email,
                  token: url,
                  password: values.newpassword,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                  },
                }
              )
              .then((response) => {
                navigate("/admin");
              });
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <Field
                name="email"
                type="text"
                placeholder="E-mail"
                className="control"
              />
              {errors.email && touched.email ? (
                <div className="error-input">{errors.email}</div>
              ) : null}

              <Field
                name="newpassword"
                type="password"
                placeholder="Nové heslo"
                className="control"
              />
              {errors.newpassword && touched.newpassword ? (
                <div className="error-input">{errors.newpassword}</div>
              ) : null}

              <Field
                name="renewpassword"
                type="password"
                placeholder="Opakuj nové heslo"
                className="control"
              />
              {errors.renewpassword && touched.renewpassword ? (
                <div className="error-input">{errors.renewpassword}</div>
              ) : null}

              <button className="control" type="submit">
                ZMĚNIT HESLO
              </button>
            </Form>
          )}
        </Formik>
        <Link to="/admin/dashboard">
          <button className="send-button">ZPĚT</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
