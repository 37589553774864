import React from "react";
import SEO from "../shared/SEO";

const WIPState = () => {
  return (
    <>
      <SEO title="Stránka ve výstavbě" />
      <div className="login-card animate-in">
        <h2>Veronika Vavrincová</h2>
        <h3 className="m-3">
          Webové stránky jsou aktuálně ve výstavbě.<br></br>Budou brzy k
          dispozici!
        </h3>
      </div>
    </>
  );
};

export default WIPState;
