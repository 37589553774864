import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { readingTime } from "reading-time-estimator";
import Unauthorized from "../../../shared/Unauthorized";

function Editor(props) {
  const [theme, setTheme] = useState("snow");
  const { id, inputRef, placeholder } = props;
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const accessToken = useSelector((state) => state.form.accessToken);
  const navigate = useNavigate();

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
  }

  const saveText = () => {
    var date = getCurrentDate("-");
    var time = readingTime(text, 200);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/blog/add`,
        {
          text: text,
          title: title,
          category: category,
          lengthRead: time.minutes,
          dateWritten: date,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        navigate("/admin/add/blog/image/" + response.data);
      });
  };

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
  return (
    <>
      <NavbarAdmin />
      <Container className="animate-in">
        <div className="spacer mobile main"></div>
        <div className="d-flex justify-content-evenly align-center">
          <Link className="editor-button left" to="/admin/list/blog">
            <button className="send-button editor">ZPĚT</button>
          </Link>
        </div>
        <div className="login-form">
          <input
            name="title"
            type="text"
            placeholder="Název"
            className="control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            name="category"
            type="text"
            placeholder="Kategorie"
            className="control mb-3"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <ReactQuill
          id={id}
          ref={inputRef}
          theme={theme}
          onChange={(value) => setText(value)}
          value={text}
          modules={{
            toolbar: {
              ...Editor.modules.toolbar,
              handlers: {},
            },
            ...Editor.modules,
          }}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={placeholder ?? ""}
        />
        <button
          className="send-button save mb-3"
          type="submit"
          onClick={() => saveText()}
        >
          PŘIDAT
        </button>
      </Container>
    </>
  );}
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    [{ align: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default Editor;
