import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <div className="login-card animate-in">
        <h2>Tato stránka neexistuje!</h2>
        <h3>Pokračujte dále na můj web</h3>
        <Link to="/">
          <button className="send-button">ZPĚT NA WEB</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
