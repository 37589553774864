import { MyBubblyLink } from "./MyBubblyLink";

export const Nav = ({active}) => (
  <>
    <nav className="animate-in desktop" style={{ animationDelay: "200ms" }}>
      <MyBubblyLink to="/" text="HOME" />
      <MyBubblyLink to="/o-mne" text="O MNĚ" />
      <MyBubblyLink to="/cenik" text="CENÍK" />
      <MyBubblyLink to="/portfolio" text="PORTFOLIO" />
      <MyBubblyLink to="/galerie" text="GALERIE" />
      <MyBubblyLink to="/kontakt" text="KONTAKT" />
      <MyBubblyLink to="/blog" text="BLOG" />
    </nav>
    <nav className={`sidebar ${active  ? "active" : ""} mobile`}>
      <ul class={`sidebar-list`}>
        <MyBubblyLink to="/" text="HOME" />
        <MyBubblyLink to="/o-mne" text="O MNĚ" />
        <MyBubblyLink to="/cenik" text="CENÍK" />
        <MyBubblyLink to="/portfolio" text="PORTFOLIO" />
        <MyBubblyLink to="/galerie" text="GALERIE" />
        <MyBubblyLink to="/kontakt" text="KONTAKT" />
        <MyBubblyLink to="/blog" text="BLOG" />
      </ul>
    </nav>
  </>
);
