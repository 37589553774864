import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Unauthorized from "../../shared/Unauthorized";
import Switch from "react-switch";
import Loading from "../../shared/Loading";

const Login = () => {
  const accessToken = useSelector((state) => state.form.accessToken);
  const [checked, setChecked] = useState(Boolean);
  const [loading, setLoading] = useState(true);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    setNewState(nextChecked);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setChecked(res.data);
        setLoading(false);
      });
  }, []);

  const setNewState = (nextChecked) => {
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/text/set/pageState/${nextChecked}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!accessToken) {
    return <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <div className="login-card animate-in">
          <h2>Stav stránky</h2>
          <h3>Běží | Pozastaven</h3>
          <Switch
            onChange={handleChange}
            checked={checked}
            onColor="#fedecd"
            onHandleColor="#fd81a8"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
          <Link to="/admin/dashboard">
            <button className="send-button">ZPĚT</button>
          </Link>
        </div>
      </>
    );
  }
};

export default Login;
