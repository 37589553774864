import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import FsLightbox from "fslightbox-react";
import axios from "axios";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import { AspectRatio } from "react-aspect-ratio";
import imageSize from "@coderosh/image-size";
import WIPState from "../shared/WIPState";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery/link`)
      .then((res) => {
        setImageUrl(res.data);
        setLoading(false);
      });
  }, []);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title="Galerie | Veronika Vavrincová"
          description="Galerie Veronika Vavrincová. Náhledy fotek z focení mých klientů."
          name="Veronika Vavrincová"
          type="Gelerie"
        />
        <Navbar />
        <Container>
          <div className="spacer mobile main"> </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={imageUrl}
            slide={lightboxController.slide}
          />
          <div className="content-page animate-in">
            <h1>GALERIE</h1>
          </div>
          <div className="image-gallery-types">
            {imageUrl.map((image, index) => {
              var ratio = imageSize(image);
              ratio = ratio.height + "/" + ratio.width;
              return (
                <div className="gallery__image" key={image.src}>
                  <AspectRatio ratio={ratio}>
                    <img
                      src={`${image}`}
                      onClick={() => openLightboxOnSlide(index + 1)}
                      alt="demo"
                    />
                  </AspectRatio>
                </div>
              );
            })}
          </div>
        </Container>
      </>
    );
  }
};

export default Gallery;
