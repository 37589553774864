import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import FileUploadEdit from "../Utils/FileUploadEdit";
import Unauthorized from "../../../shared/Unauthorized";
import Loading from "../../../shared/Loading";

const ImageEdit = () => {
  const accessToken = useSelector((state) => state.form.accessToken);

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/image/about/1`)
      .then((res) => {
        setFileName(res.data.fileName);
        setLoading(false);
      });
  }, []);

  if (!accessToken) {
    <Unauthorized />;
  } else if (loading) {
    <Loading />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer"> </div>
          <Link className="editor-button right" to="/admin/dashboard">
            <button className="send-button editor">ZPĚT</button>
          </Link>
          <div className="image-editer">
            <img
              src={`/assets/images/about/${fileName}`}
              alt="edit"
              className="aboutphoto"
            />
            <div className="image-edit-section">
              <button
                className="send-button mb-5"
                onClick={() => setEdit(!edit)}
              >
                ZMĚNIT FOTKU
              </button>
              {edit ? (
                <>
                  <FileUploadEdit edit={"edit"} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default ImageEdit;
