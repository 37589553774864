import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import SendForm from "../shared/SendForm";
import "../assets/styles/fonts.css";
import { Navbar } from "../shared/Navbar";
import { InstagramGallery } from "instagram-gallery";
import SEO from "../shared/SEO";
import axios from "axios";
import WIPState from "../shared/WIPState";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title="Kontakt | Veronika Vavrincová"
          description="Kontakt Veronika Vavrincová. Neváhejte mě kontaktovat"
          name="Veronika Vavrincová"
          type="Kontakt"
        />
        <Navbar />
        <Container className="animate-in">
          <div className="spacer mobile main"> </div>
          <Row>
            <Col xl={6} lg={6}>
              <SendForm text={"ODESLAT"} />
            </Col>
            <Col xl={6} lg={6}>
              <div className="kontakt-info">
                <div className="kontakt-desc">
                  <span className="first">
                    <i class="bi bi-person"></i>Mgr. Veronika Vavrincová
                  </span>
                  <span>
                    <i class="bi bi-phone"></i>
                    <a href="tel:+420732449545">+420 732 449 545</a>
                  </span>
                  <span>
                    <i class="bi bi-envelope"></i>
                    <a
                      href="mailto:
                    info@veronikavavrincova.cz"
                    >
                      info@veronikavavrincova.cz
                    </a>
                  </span>
                  <span>IČO: 08339945</span>
                  <span className="social-media">
                    <a
                      href="https://www.facebook.com/apacheee.photography"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bi bi-facebook"></i>
                    </a>
                    <a
                      href="https://wa.me/732449545"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bi bi-whatsapp"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/apacheee_photography/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bi bi-instagram"></i>
                    </a>
                  </span>
                  <h3 className="mt-3">Navštivte můj instagram</h3>
                  <InstagramGallery
                    accessToken="IGQVJWOUp5bTQ3X25NS2ZAGNy0wMG1wUlJScWtGMWg5V0JiTVltbkU1UnZAHU2ZAwNC04QS1DYXJ4WDZA0MF9XWktJSUM3N3hSb1J6NEpkQ2hER3ZAUaVJQWW95UzBhVm9iaWphYnNtTmNB"
                    count={9}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <footer>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p>
                Made&nbsp;with&nbsp;❤️&nbsp;by&nbsp;
                <a
                  className="tomas"
                  targer="_blank"
                  href="https://tomas-kulhavy.cz/"
                >
                  @tomaskulhavy
                </a>
              </p>
            </div>
          </div>
        </footer>
      </>
    );
  }
};

export default About;
