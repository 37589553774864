import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Povinné"),
});

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="login-card animate-in">
        <h2>Zapomenuté heslo</h2>
        <h3></h3>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/account/forgotPassword`,
                {
                  email: values.email,
                  password: values.password,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                navigate("/admin");
              });
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <Field
                name="email"
                type="text"
                placeholder="E-mail"
                className="control"
              />
              {errors.email && touched.email ? (
                <div className="error-input">{errors.email}</div>
              ) : null}

              <button className="control" type="submit">
                ODESLAT
              </button>
            </Form>
          )}
        </Formik>
        <Link to="/admin">
          <button className="send-button">ZPĚT</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
