import React, { useState, useEffect } from "react";
import { NavAdmin } from "./NavAdmin";
import Logo from "../../assets/images/logo.svg";
import { Cross as Hamburger } from "hamburger-react";

export const NavbarAdmin = () => {
  const [colorChange, setColorchange] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 50) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  function blockit(boolHamb) {
    var cover = document.getElementById("cover");

    if (boolHamb) {
      cover.classList.add("covering");
    } else if (!boolHamb) {
      cover.classList.remove("covering");
    }
  }

  useEffect(() => {
    blockit(isOpen);
  }, [isOpen]);

  return (
    <>
      <div id="cover"></div>
      <header className={`${colorChange ? "active" : ""}`}>
        <a href="/">
          <img
            src={`${Logo}`}
            className={`active logo-nav`}
            style={{ animationDelay: "600ms" }}
            alt="logo"
          />
        </a>
        <NavAdmin active={isOpen} />
        <div className="navmenu-flex-basket">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color="#fd81a8"
            rounded
          />
        </div>
      </header>
    </>
  );
};
