import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import axios from "axios";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import WIPState from "../shared/WIPState";

const About = () => {
  const [text, setText] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/about/page`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setText(response.data.text);
        setFileName(response.data.fileName);
        setLoading(false);
      });
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`O mně | Veronika Vavrincová`}
          description={`${text.split(" ").slice(0, 50).join(" ")}...`}
          name="Veronika Vavrincová"
          type="O mně"
        />
        <Navbar />
        <Container>
          <div className="spacer mobile main"> </div>
          <Row className="animate-in">
            <Col xl={5} lg={5} align="center">
              <img
                src={`/assets/images/about/${fileName}`}
                alt="Veronika Vavrincová"
                className="aboutphoto"
              />
            </Col>
            <Col xl={7} lg={7}>
              <h1 className="about-mobile">Veronika Vavrincová</h1>
              <h3>fotografka</h3>
              <article className="about-text">
                <br></br>
                {<UnsafeComponent html={text} />}
              </article>
              <Link to="/kontakt">
                <button className="send-button mb-3">KONTAKTUJTE MĚ</button>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default About;
