import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import WIPState from "../shared/WIPState";

const TypePhoto = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/portfolio/home/preview`)
      .then((res) => {
        setPortfolio(res.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/header/portfolio`)
      .then((res) => {
        setFileName(res.data);
        setLoading(false);
      });
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  function renderPortfolio() {
    return portfolio.map((item, index) => {
      return (
        <Col
          className="col-sm-12 col-md-6 col-lg-6 col-xl-4"
          align="center"
          key={index}
        >
          <Link to={`/portfolio/${item.id}`}>
            <div className="card-content portfo">
              <img
                className="card-image-content"
                src={`/assets/images/portfolio/${item.id}/${item.imageToPortfolios.fileName}`}
                alt="druhy foceni"
              />
              <p>{item.title}</p>
            </div>
          </Link>
        </Col>
      );
    });
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`Portfolio | Veronika Vavrincová`}
          description={`Portfolio | Veronika Vavrincová`}
          name="Veronika Vavrincová"
          type="Portfolio"
        />
        <Navbar />
        <div className="spacer mobile main"> </div>
        <div className="page-header animate-in">
          <img
            class="page-img-header"
            src={`/assets/images/portfolio/${fileName}`}
            alt="Portfolio"
          />
          <h1>PORTFOLIO</h1>
        </div>
        <Container>
          <div className="content-page-items types animate-in">
            <div className="types-cards">
              <Row>{renderPortfolio()}</Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default TypePhoto;
