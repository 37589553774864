import { Routes, Route, BrowserRouter } from "react-router-dom";
import configureStore from "./reducer/configureStore";
import About from "./routes/About";
import TypePhoto from "./routes/TypePhoto";
import Contact from "./routes/Contact";
import Unauthorized from "./shared/Unauthorized";
import { BubblyContainer } from "react-bubbly-transitions";
import Blog from "./routes/Blog";
import Home from "./routes/Home";
import Gallery from "./routes/Gallery";
import TypePhotoDesc from "./routes/TypePhotoDesc";
import Pricing from "./routes/Pricing";
import BlogDesc from "./routes/BlogDesc";
import Login from "./routes/admin/Login";
import Dashboard from "./routes/admin/Dashboard";
import TextEdit from "./routes/admin/About/TextEdit";
import { Provider } from "react-redux";
import ImageEdit from "./routes/admin/About/ImageEdit";
import GalleryList from "./routes/admin/Gallery/GalleryList";
import PortfolioList from "./routes/admin/Portfolio/PortfolioList";
import PortfolioAdd from "./routes/admin/Portfolio/PortfolioAdd";
import PortfolioUploadMainImage from "./routes/admin/Portfolio/PortfolioUploadMainImage";
import PortfolioEdit from "./routes/admin/Portfolio/PortfolioEdit";
import PortfolioUploadImage from "./routes/admin/Portfolio/PortfolioUploadImage";
import PortfolioImagesList from "./routes/admin/Portfolio/PortfolioImagesList";
import BlogList from "./routes/admin/Blog/BlogList";
import BlogAdd from "./routes/admin/Blog/BlogAdd";
import BlogUploadMainImage from "./routes/admin/Blog/BlogUploadMainImage";
import BlogEdit from "./routes/admin/Blog/BlogEdit";
import BlogUploadImage from "./routes/admin/Blog/BlogUploadImage";
import BlogImagesList from "./routes/admin/Blog/BlogImagesList";
import GalleryUpload from "./routes/admin/Gallery/GalleryUpload";
import ThanksPage from "./shared/ThanksPage";
import Logout from "./routes/admin/Logout";
import ChangePassword from "./routes/admin/ChangePassword";
import ResetPassword from "./routes/admin/ResetPassword";
import ForgotPassword from "./routes/admin/ForgotPassword";
import NotFound from "./shared/NotFound";
import { PersistGate } from "redux-persist/lib/integration/react";
import Loading from "./shared/Loading";
import HeaderImageEdit from "./routes/admin/HeaderImageEdit";
import MainImagesList from "./routes/admin/Main/MainImagesList";
import MainUploadImage from "./routes/admin/Main/MainUploadImage";
import TextEditMain from "./routes/admin/Main/TextEditMain";
import StateWebsite from "./routes/admin/StateWebsite";

function App() {
  const { store, persistor } = configureStore();

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <BrowserRouter>
          <BubblyContainer />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/o-mne" element={<About />} />
            <Route exact path="/kontakt" element={<Contact />} />
            <Route exact path="/cenik" element={<Pricing />} />
            <Route exact path="/portfolio" element={<TypePhoto />} />
            <Route exact path="/portfolio/:id" element={<TypePhotoDesc />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:id" element={<BlogDesc />} />
            <Route exact path="/galerie" element={<Gallery />} />
            <Route exact path="/dekuji" element={<ThanksPage />} />
            <Route exact path="/admin" element={<Login />} />
            <Route
              exact
              path="/admin/website-state"
              element={<StateWebsite />}
            />
            <Route
              exact
              path="/admin/change/password"
              element={<ChangePassword />}
            />
            <Route exact path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset" element={<ResetPassword />} />
            <Route exact path="/admin/logout" element={<Logout />} />
            <Route exact path="/admin/dashboard" element={<Dashboard />} />
            <Route exact path="/admin/text-edit/about" element={<TextEdit />} />
            <Route
              exact
              path="/admin/text-edit/main"
              element={<TextEditMain />}
            />
            <Route
              exact
              path="/admin/header/:type/image"
              element={<HeaderImageEdit />}
            />
            <Route
              exact
              path="/admin/image/upload/about"
              element={<ImageEdit />}
            />
            <Route
              exact
              path="/admin/image/list/main"
              element={<MainImagesList />}
            />
            <Route
              exact
              path="/admin/image/upload/main"
              element={<MainUploadImage />}
            />
            <Route
              exact
              path="/admin/image/list/gallery"
              element={<GalleryList />}
            />
            <Route
              exact
              path="/admin/image/upload/gallery"
              element={<GalleryUpload />}
            />
            <Route
              exact
              path="/admin/list/portfolio"
              element={<PortfolioList />}
            />
            <Route
              exact
              path="/admin/add/portfolio"
              element={<PortfolioAdd />}
            />
            <Route
              exact
              path="/admin/edit/portfolio/:id"
              element={<PortfolioEdit />}
            />
            <Route
              exact
              path="/admin/add/portfolio/image/:id"
              element={<PortfolioUploadMainImage />}
            />
            <Route
              exact
              path="/admin/add/portfolio/images/:id"
              element={<PortfolioUploadImage />}
            />
            <Route
              exact
              path="/admin/add/portfolio/images/list/:id"
              element={<PortfolioImagesList />}
            />
            <Route exact path="/admin/list/blog" element={<BlogList />} />
            <Route exact path="/admin/add/blog" element={<BlogAdd />} />
            <Route exact path="/admin/edit/blog/:id" element={<BlogEdit />} />
            <Route
              exact
              path="/admin/add/blog/image/:id"
              element={<BlogUploadMainImage />}
            />
            <Route
              exact
              path="/admin/add/blog/images/:id"
              element={<BlogUploadImage />}
            />
            <Route
              exact
              path="/admin/add/blog/images/list/:id"
              element={<BlogImagesList />}
            />
            <Route exact path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
