import React from "react";
import { Link, useParams } from "react-router-dom";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container } from "reactstrap";
import FileUploadMain from "../Utils/FileUploadMain";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const MainUploadImage = () => {
  const { id } = useParams();
  const accessToken = useSelector((state) => state.form.accessToken);

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
  return (
    <>
      <NavbarAdmin />
      <Container className="animate-in">
        <div className="spacer main"> </div>
        <Link to={`/admin/image/list/main`}>
          <button className="send-button editor">ZPĚT</button>
        </Link>
        <h1 className="mb-3">Nahraj fotky na hlavní stránku</h1>
        <FileUploadMain id={id} />
      </Container>
    </>
  );}
};

export default MainUploadImage;
